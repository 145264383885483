<template>
  <b-card header-tag="header">
    <div class="filter-transaction">
      <filter-reconcile
          @handleFilter="handleFilter"
      ></filter-reconcile>
    </div>
    <b-table
        :fields="fields"
        :items="items"
        details-td-class="datatable-cell"
        hover
        table-class="datatable-table"
        tbody-tr-class="datatable-row"
        thead-class="datatable-head"
        thead-tr-class="datatable-row"
    >
      <template #cell(detail)="data">
        <a :href="`${data.value}`">Chi tiết</a>
      </template>
    </b-table>
    <div class="mt-3">
      <b-pagination-nav
          v-model="currentPage"
          :link-gen="createLinkPage"
          :number-of-pages="paginate.totalPage"
          use-router
          first-number
          last-number
          align="right"
      ></b-pagination-nav>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import filterReconcile from "@/view/pages/reconcile_cashback/components/filterReconcile.vue";
import CmsRepository from "@/core/repositories/cmsRepository";

export default {
  name: "Reconcile",
  mixins: [Common],
  components: {
    filterReconcile,
  },
  data() {
    return {
      filter: {},
      fields: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "reconcile_date",
          label: "Ngày đối soát",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "total_reconcile",
          label: "Tổng giao dịch",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "match",
          label: "Giao dịch khớp",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "not_match",
          label: "Giao dịch sai lệch",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "detail",
          label: "Chi tiết",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  watch: {
    currentPage() {
      this.getList();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách giao dịch đối soát" },
    ]);
    this.filter = this.$route.query;

    this.getList();
  },
  methods: {
    async getList() {
      try {
        this.$bus.$emit("show-loading", true);
        let params = this.filter;
        params.page = this.currentPage;
        params.limit = this.paginate.limit;
        let response = await CmsRepository.listCashback(params);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        this.items = body.data.data.data;

        if (this.items.length) {
          this.items.forEach((value) => {
            value["detail"] = "#/reconcile/cashback-detail?id=" + value.id;
          });
        }

        this.paginate.total = body.data.data.total;
        this.paginate.totalPage = body.data.data.last_page;
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    handleFilter(filter) {
      this.filter = filter;
      this.getList();
    },
  },
};
</script>

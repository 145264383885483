<template>
  <div>
    <div class="row mb-8">
      <div class="col-lg-4 mb-lg-0 mb-6">
        <label>Ngày đối soát:</label>
        <div class="input-daterange input-group" id="kt_datepicker">
          <input
            type="date"
            v-model="filter.date_from"
            class="form-control datatable-input"
            name="start"
            placeholder="Từ"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="la la-ellipsis-h"></i>
            </span>
          </div>
          <input
            type="date"
            v-model="filter.date_to"
            class="form-control datatable-input"
            name="end"
            placeholder="Đến"
          />
        </div>
      </div>

      <div class="col-lg-2 mb-lg-0 mb-6">
        <label>Tổng số giao dịch:</label>
        <input
          type="number"
          min="0"
          class="form-control datatable-input"
          v-model="filter.total_reconcile"
        />
      </div>

      <div class="col-lg-2 mb-lg-0 mb-6">
        <label>Số giao dịch khớp:</label>
        <input
          type="number"
          min="0"
          class="form-control datatable-input"
          v-model="filter.match"
        />
      </div>

      <div class="col-lg-2 mb-lg-0 mb-6">
        <label>Giao dịch không khớp:</label>
        <input
          type="number"
          min="0"
          class="form-control datatable-input"
          v-model="filter.not_match"
        />
      </div>
    </div>
    <div class="row mt-8">
      <div class="col-lg-12 text-right">
        <button
          class="btn btn-primary btn-primary--icon"
          @click="createLinkSearch()"
        >
          <span>
            <i class="la la-search"></i>
            <span>Tìm kiếm</span>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "filterReconcile",
  data() {
    return {
      filter: {},
    };
  },
  components: {},
  mounted() {},
  methods: {
    createLinkSearch() {
      this.$emit("handleFilter", this.filter);
    },
  },
};
</script>
